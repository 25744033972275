<template>
    <span>{{ name }}</span>
</template>

<script>
export default {

    props: {
        // whether the item should link to the organization record; a parent component may set this to false if it just wants the user to select an organization (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        name() {
            return this.attr.name;
        },
    },

};
</script>
